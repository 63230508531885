<template>
  <div class="content container">
    <PageHeader :title="$t('acc-settings.create.name')" />
    <div class="page-content">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <form @submit.prevent="create">
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label for="demoTextInput1">{{
                        $tc('generic-str.name', 1)
                      }}</label>
                      <input
                        v-model="form.name"
                        type="text"
                        class="form-control"
                        id="demoTextInput1"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer bg-light">
                <div class="row">
                  <div class="offset-md-10 col-md-2 col-12">
                    <button
                      type="submit"
                      class="btn btn-primary btn-block"
                      :class="{ 'btn-block-ui': isSending }"
                      :disabled="isSending"
                    >
                      {{ $t('create') }}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import AccountService from '@/services/account.service';
import PageHeader from '@/components/PageHeader.vue';

export default {
  name: 'Accounts',
  components: {
    PageHeader,
  },
  data() {
    return {
      isSending: false,
      form: {
        name: '',
      },
    };
  },
  methods: {
    switchAccount(accountId) {
      AccountService.switchAccount(accountId);
    },
    create() {
      this.isSending = true;
      AccountService.createAccount(this.form).then(
        (response) => {
          this.$store.dispatch('account/select', response.id).then(
            () => {
              this.$router.push('/');
              this.$root.$emit('change.account', response.id);
            },
            (error) => {
              console.log(error);
            },
          );
          this.loaded = true;
          this.isSending = false;
        },
        (error) => {
          this.content = error;
          this.isSending = false;
        },
      );
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.account-list {
  min-height: 250px;
}
.account-list .card {
  cursor: pointer;
  &:hover {
    box-shadow: 0 10px 40px 0 rgba(18, 106, 211, 0.7),
      0 2px 9px 0 rgba(18, 106, 211, 0.6);
  }
}
</style>
